import React from 'react'
import { Form } from "react-bootstrap"
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';

function myplaceholder(props) {
  return <div>{props.html}</div>;
}

function createMarkup(props) {
  return {__html: (props.html)};
}

function MyComponent(props) {
  return <div dangerouslySetInnerHTML={createMarkup(props)} />;
}

const CheckboxField = ({fieldClass, name, value, required, placeholder, handlechange, label, type, step, grpmd}) => (
<>
{name == 'sell_let' ?

<Row className={`${type} ${step}`}>
<p>Are you looking to Sell or Let?</p>
<Col md={6}>
  <div className="form-group" id="ck-button">
      <label className="check-card mb-4 pb-2"><MyComponent html={placeholder} />
          <input required={required} className="" type="checkbox" id="sell" name="sell" value="sell" /><span>Sell</span>
      </label>
  </div>
</Col>
<Col md={6}>
  <div className="form-group" id="ck-button">
      <label className="check-card mb-4 pb-2"><MyComponent html={placeholder} />
          <input required={required} className="" type="checkbox" id="let" name="let" value="let" /><span>Let</span>
      </label>
  </div>
</Col>
<p className="small">If you’d like both, you can confirm this on the confirmation screen.</p>
</Row>
:
  <Form.Group as={Col} md={grpmd} className={`${type} ${step} ${fieldClass}`} controlId={"validation" + name}>
{ label
      ? <p className="label"><Form.Label className="form-label">{label}{required ? '*' : ''}</Form.Label></p>
      : ''
    }
      <label className="check-card mb-4 pb-2">
          <input required={required} className="" type="checkbox" id={name} name={name} value={value} /><MyComponent html={placeholder} />
          <span className="check-mark"></span>
      </label>
  </Form.Group>
}
 </>
);

export default CheckboxField