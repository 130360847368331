import React from 'react'
import { Button, Form } from "react-bootstrap"
import { Row, Col, Container, Breadcrumb } from 'react-bootstrap';
import { Link } from "@StarberryUtils"
import HTMLReactParser from 'html-react-parser';

const ButtonField = ({ type, name, value, fieldClass, step, handlechange, handlechange2, handlechange3, handlechange4, handlebackchange, handlebackchange2, handlebackchange3, handlebackchange4, grpmd, nextStep, backStep }) => (
	<>
		{step == 'steptwo' &&
			<>
				<Button onClick={() => { nextStep(3) }} variant="" type={type} className="btn-step btn-step-next btn btn-primary arrow-btn">
				<i className="right-arrow"></i>
				</Button>
			</>
		}

		{step == 'stepthree' &&
			<Button onClick={() => { nextStep(4) }} variant="" type={type} className="btn-step btn-step-next btn btn-primary arrow-btn">
					<i className="right-arrow"></i>
			</Button>
		}
		{step == 'stepfour' &&
			<Button variant="" type={type} className="btn-step btn-step-next btn btn-primary">
				{HTMLReactParser(name.replace(/<br\s*\/?>/gi, ''))}
			</Button>
		}
		{step != 'stepone' && step != 'steptwo' && step != 'stepthree' && step != 'stepfour' && step != 'stepfive' &&
		    <Form.Group as={Col} md={grpmd} className={`col-md-12 ${type} ${step}`}>
				<Button variant="" type={type} className={fieldClass}>
					{name}
					{step !== 'nostep' &&
						<i className="icon-arrow"></i>
					}
				</Button>
			</Form.Group>
		}
	</>
);

export default ButtonField;
